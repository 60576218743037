/* custom fonts */
@font-face {
    font-family: 'open_sans_bold';
    src: url('./fonts/opensans-bold-webfont.woff2') format('woff2'),
    url('./fonts/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sans_regular';
    src: url('./fonts/opensans-regular-webfont.woff2') format('woff2'),
    url('./fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sans_semibold';
    src: url('./fonts/opensans-semiBold-webfont.woff2') format('woff2'),
    url('./fonts/opensans-semiBold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* custom fontes */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-file-label {
  top: 11px;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textCardColor {
  color: #fff;
}

.colorBack {
  background-color: #f5f5f5;
}

.iconHeaderColor {
  background-color: #424242;
}

.backgroundFooterColor {
  background-color: #424242;
}

.file-field.medium .file-path-wrapper {
  height: 3rem;
}
.file-field.medium .file-path-wrapper .file-path {
  height: 2.8rem;
}

.file-field.big-2 .file-path-wrapper {
  height: 3.7rem;
}
.file-field.big-2 .file-path-wrapper .file-path {
  height: 3.5rem;
}

.modal {
  display: none;
}

.gv-table-view tfoot {
  display: none;
}

.gv-datatables tfoot {
  display: none;
}

.table {
  margin-bottom: 0px;
}

.table td,
.table th {
  vertical-align: middle;
}

/* Admin Menu */
.button-admin {
  cursor: pointer;
  color: #fff;
  background-color: #7e7e7e;
  margin: 0.375rem;
  text-transform: uppercase;
  white-space: normal;
  border: 0;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  width: 22rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-admin:hover {
  color: #fff;
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  text-decoration: none;
  cursor: pointer;
}

.button-admin:focus {
  outline: 0;
  background-color: #575555;
}

.button-admin:active {
  background-color: #575555;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.admin-menu {
  background-color: #b4b4b4;
  text-align: center;
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 2rem 2rem 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.admin-menu-header {
  text-align: center;
  padding: 0.75rem 1.25rem 0 1.25rem;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.2rem;
}

.buttons-admin-menu {
  width: 100%;
  flex-direction: row;
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
}

/* Upload e Download Boxes */
.download-and-upload {
  min-height: 13rem;
  box-sizing: border-box;
  margin: auto 0;
}

/* Upload Box */
.upload-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.upload-wrapper {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-area {
  margin-right: 1rem;
}

.deactivate-recaptcha {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
  opacity: 30%;
}

.custom-file {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}

.custom-file-label {
  margin: 0 auto;
  text-align: left;
}

#select-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;
  justify-items: center;
  margin: 0 auto 0.6rem auto;
}

#select-box-partner {
  justify-self: right;
}

#select-container label {
  margin-right: 0.3rem;
  margin-bottom: 0;
}

#select-box-campaign label {
  margin-left: 0.7rem;
}

#select-container #select-container div:nth-child(2) {
  margin-left: 1rem;
}

#select-container.alone {
  display: unset;
  margin-top: 0.6rem;
}

.select-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.col-md-6#upload-box {
  flex: 0 0 60%;
  max-width: 60%;
  margin-bottom: 0.7rem !important;
}

.upload-wrapper .loading-msg {
  margin-bottom: 0.6rem;
}

.no-campaigns-msg {
  color: rgb(97, 97, 97);
  padding-left: 1rem;
}

/* Download Box */
.card-body#download-body {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.card-body#fetch-points-body {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  margin-top: 25px;
}

.card-body .points-number {
  color: #ff3547;
  font-weight: 700 !important;
}

button#download-csv,
button#download-manual {
  width: 18rem;
}

.col-md-6#download-box {
  flex: 0 0 40%;
  max-width: 40%;
  margin-bottom: 0.7rem !important;
}

.col-md-6#fetch-points-box {
  flex: 0 0 40%;
  max-width: 40%;
  margin-bottom: 0.7rem !important;
}

button#download-manual:disabled {
  background-color: #4285f480;
}

button#download-csv:disabled {
  background-color: #66c9bf78;
}

/* Table Imports */
.table-upoloads {
  margin-bottom: 8rem !important;
}

/* Views Partner and Form */
#page-wrapper {
  text-align: center;
}

#list-container {
  max-width: 100rem;
  padding: 0;
  margin: 0 auto;
}

#list-container .loading-msg {
  margin: 3rem 0 2rem 0;
}

.button-admin#create-new-partner {
  margin-bottom: 8rem;
}

/* Buttons Partner/Campaign */
div#page-buttons {
  margin: 2rem;
}

div#list-container {
  margin: 0 auto;
}

div#list-container {
  display: flex;
  flex-direction: column;
}

div#list-container button {
  border: 0;
  background-color: unset;
  text-align: center;
  padding: 0.8rem 1rem;
  font-weight: 300;
  font-size: 1.2rem;
}

div#list-container button:first-child {
  margin: 1rem 0;
}

div#list-container button:hover {
  background-color: whitesmoke;
}

div#list-container button:focus {
  outline: unset;
}

div#list-container {
  margin-bottom: 8rem;
}

table.table-list {
  margin: 0 auto 2rem auto;
}

table.table-list thead th {
  font-size: 1rem;
  text-align: center;
  padding-bottom: 0.5rem;
}

table.table-list thead tr th,
table.table-list tbody tr td {
  padding-left: 2rem;
  padding-bottom: 0.9rem;
}

table.table-list thead tr th + th,
table.table-list tbody tr td + td {
  padding-right: 0.5rem;
}

table.table-list tbody td {
  text-align: center;
}

table.table-list tbody tr:hover {
  background-color: whitesmoke;
}

#list-container .select-box {
  display: flex;
  flex-direction: column;
}

/* Table Campaigns */
#select-box-list-campaigns {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#select-box-list-campaigns label {
  margin-bottom: 0.8rem;
}

#select-box-list-campaigns select {
  margin: 0 auto;
}

#table-campaigns {
  margin-top: 2rem;
}

table#table-campaigns {
  table-layout: auto;
  width: 100%;
}

#table-campaigns thead tr th + th,
#table-campaigns tbody tr td + td {
  padding-left: 0.8rem;
}

#table-campaigns thead tr th:last-child,
#table-campaigns tbody tr td:last-child {
  padding-right: 2rem;
}

#table-campaigns button.button-edit {
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0 !important;
  padding: 0 !important;
  color: rgb(9, 9, 172);
}

/* Form */
#page-wrapper {
  margin: 2rem;
  display: flex;
  flex-direction: column-reverse;
}

#form-container {
  max-width: 34rem;
  padding: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.input-block {
  display: block;
  margin-bottom: 0.5rem;
}

.input-block label {
  font-size: 0.95rem;
}

.input-block span {
  font-size: 0.85rem;
  color: #5f5f5f;
}

.span-color {
  font-size: 0.85rem;
  color: #5f5f5f;
}

.input-block input {
  width: 100%;
  border: 1px solid #a3a3a3;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 0.3rem 0.8rem;
  font-size: 0.95rem;
  transition: all 0.2s;
}

.input-block:last-child {
  margin-bottom: 2rem;
}

.input-block input:focus {
  outline: 0;
  background-color: #f0f0f0;
}

input.hidden {
  background: #dbdbdb;
}

input.hidden:focus {
  background-color: #dbdbdb;
}

.radio-div {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.radio-div:last-child {
  margin-bottom: 0.6rem;
}

.input-block fieldset .radio-div input {
  width: auto;
  box-shadow: none;
  margin-right: 0.5rem;
}

.input-block fieldset .radio-div label {
  font-size: 0.9rem;
  margin: 0;
  color: #5c5c5c;
}

div.input-date-area {
  display: flex;
}

.checkBox-space {
  margin-left: 0.5rem;
}

.error-msg {
  color: red;
  margin-bottom: 0.8rem;
}

.error-msg-parity {
    color: red;
    margin-bottom: 0.8rem;
    width: 200px;
  }

.loading-msg {
  color: rgb(97, 97, 97);
}

div.success-msg {
  margin-top: 4rem;
  text-align: center;
  font-size: 1.2rem;
}

div.fail-msg {
  color: red;
  margin-top: 4rem;
  text-align: center;
  font-size: 1.2rem;
}

.button-admin#send-form {
  margin: 2rem auto 0.7rem auto !important;
  width: 60%;
  box-sizing: border-box;
}

.button-admin#delete-form-campaign {
  margin: 0.7rem auto 1rem auto !important;
  width: 60%;
  box-sizing: border-box;
  background-color: #cc5858;
}

.button-admin#delete-form-campaign:hover {
  background-color: #b32e2e;
}

#clean-start-date-input,
#clean-deadline-input {
  font-size: 1rem;
  font-weight: 300;
  background: none;
  color: rgb(23, 58, 172);
  border: 0;
  margin-left: 1rem;
}

#clean-start-date-input:focus,
#clean-deadline-input:focus {
  outline: 0;
}

#clean-start-date-input:hover,
#clean-deadline-input:hover {
  color: rgb(11, 26, 77);
}

input.hidden {
  background: #dbdbdb;
}

.input-block#partner_name_container {
  display: flex;
  flex-direction: column;
}

select {
  padding: 0.4rem;
  width: 16rem;
  font-size: 0.95rem;
  color: #212529;
}

select:focus {
  outline: 0;
}

select:disabled {
  background-color: #e9ecef;
  color: #5a6066;
}

label.label-disabled {
  color: #5a6066;
}

.button-admin:hover {
  background-color: #535353;
}

.button-wrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 6rem !important;
}

.button-wrapper-cooperator {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem !important;
}

.btn:disabled {
  background-color: #a3a3a3;
}

/* Footer  */
#footer-portal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Max */

@media (max-width: 1263px) {
  .buttons-admin-menu button {
    width: auto;
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .download-and-upload {
    min-height: unset;
  }

  .mb-4,
  .my-4 {
    margin-bottom: 1rem !important;
  }

  #table-container {
    margin-bottom: 8rem !important;
  }

  #upload-and-download-container {
    flex-direction: column;
  }

  .col-md-6#download-box {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-6#fetch-points-box {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-6#upload-box {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card-body#download-body {
    display: flex;
    flex-direction: row !important;
    margin: 0 auto;
  }

  .card-body#fetch-points-body {
    display: flex;
    flex-direction: row !important;
    margin: 0 auto;
    margin-top: 25px;
  }

  button#download-csv,
  button#download-manual {
    margin: 0.375rem;
  }
}

@media (max-width: 992px) {
  #main-container {
    margin: 0 1rem !important;
  }
}

@media (max-width: 780px) {
  .card-body#download-body {
    flex: 0;
    flex-direction: column !important;
    /* margin: 0 auto; */
  }

  .card-body#fetch-points-body {
    flex: 0;
    flex-direction: column !important;
  }

  #select-container {
    display: flex;
    flex-direction: column;
  }

  #select-box-partner {
    margin: 1rem auto 0.5rem auto;
  }

  #select-box-campaign {
    margin: 0.5rem auto 0.5rem auto;
  }

  div.upload-area {
    display: flex;
    flex-direction: column;
  }

  div.buttons-area {
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
  }

  .custom-file-label {
    width: 100%;
  }

  .input-area {
    margin: 0 auto;
  }

  .buttons-area {
    margin: 0 auto;
  }
}

@media (max-width: 680px) {
  .buttons-admin-menu button {
    width: 88%;
    margin: 0.5rem 0.375rem;
  }

  button#download-manual,
  button#download-csv {
    width: 100%;
    margin: 0.5rem 0.375rem;
  }

  .upload-area {
    width: 100%;
  }

  #select-container {
    width: 100%;
  }

  #select-box-campaign,
  #select-box-partner {
    width: 100%;
  }

  .custom-file-label {
    width: 90%;
  }
  
  .modal-body {
    padding: 0 !important;
  }

  .rdrMonthsVertical{
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
}

/* Min */
@media (min-width: 768px) {
  .custom-file {
    max-width: 100%;
    display: block;
  }
  .rdrMonthsVertical{
    margin-left: 15% !important;
    margin-right: 15% !important;
  }
}

/* Text Editor */
.RichEditor-root {
  border: 1px solid #a3a3a3;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  padding: 15px;
  color: #5f5f5f;
}

.RichEditor-root .RichEditor-editor span {
  color: #212529;
  font-size: 0.9rem;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  color: #212529;
  /* digite o regulamento.. */
  font-size: 0.9rem;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-styleButton:hover {
  text-decoration: underline;
}

.RichEditor-activeButton {
  color: #5890ff;
}

/* Filter Top Table */
.filter-top-table {
  margin-left: .5rem;
  font-weight: 500;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.filter-top-table-between {
  margin-left: 1.2rem;
  font-weight: 500;
}
.filter-top-table-right-filter {
  position: absolute;
  font-weight: 500;
  right:1.2rem;
}
.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.rdrCalendarWrapper {
  display: block !important;
}
.rdrMonthPicker select{
  width: auto !important;
}
.rdrYearPicker select{
  width: auto !important;
}
.rdrMonthsVertical{
  flex-direction: unset !important;
}

#pagination-manual-label {
  padding-right: .5rem;
  padding-top: .4rem;
  font-weight: 400;
  text-align: left;
  font-size: .9rem;
  font-family: "Roboto",sans-serif;
}

#pagination-manual-number {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  font-weight: 400;
  font-size: .9rem;
  font-family: "Roboto",sans-serif;
  height: 2.2rem;
  width: 3rem;
  vertical-align: middle;
  text-align: center;
  margin-right: 1rem;
}

#pagination-manual-number:focus {
  outline: none;
  box-shadow: 0 0 2px 3px rgb(145, 202, 248);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.div-parity {
  display: flex;
}

.div-parity-error {
  display: flex;
  height: 55px;
}

.input-block-inside-div {
    padding-right: 18px;
}

.input-block-inside-div-error {
    padding-right: 58px;
}

.div-plus {
  display: flex;
  align-items: end;
}

.plus {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000000;
  padding-right: 18px;
  margin-bottom: 11px;
}

.sum-points {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000000;
  margin-bottom: 25px;
}

/* toggle switch button */

.switch__container-points {
  display: flex;
  padding: 32px 0px 24px 0px;
}

.switch__container-campaign {  
  display: flex;
  padding: 0px 0px 32px 0px;
}

.switch {
  visibility: hidden;
  display: none;
/*   position: absolute; */
/*   margin-left: -9999px; */
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow + label {
  padding: 2px;
  width: 48px;
  height: 24px;
  background-color: #dddddd;
  border-radius: 60px;  
}
.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.switch--shadow + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: background 0.4s;
}
.switch--shadow + label:after {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
  margin-top: 3px;
}
.switch--shadow:checked + label:before {
  background-color: #63BA68;
}
.switch--shadow:checked + label:after {
  transform: translateX(23px);  
}

.div-switch {
  width: 100%;
  padding-left: 16px;
}

.span-title-switch {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px; 
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000000;
}

.span-p-switch {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #767676;
}
  

/*  */

.popover__wrapper {
  position: relative;
  /* margin-top: 1.5rem; */
  display: inline-block;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -91px;
  transform: translate(0, 7px);
  background-color: #bfbfbf;
  padding: 8px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  width: 197px;
  background: #444444;
  border: 1px solid #444444;
  border-radius: 4px;
  height: auto;
  margin-top: 27px;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #444 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 0;
}

/* FERA-2972 [START] */
.input-grid{
  display: grid;
}

.checkbox_block{
  display: flex;
  align-items: baseline;
}

.input-block#subpartner_name_container {
  display: flex;
  flex-direction: column;
} 

#popover_subpartner{
  height: 60px;
}
.RichEditor-editor {
  font-size: 0.8rem;
}
/* FERA-2972 [END] */
/* FERA-2791 [START] */
.button-add#create-new-partner {
  margin-bottom: 8rem;
}

.button-add:hover {
  background-color: #535353;
}

.button-wrapper-add {
  width: 100%;
  text-align: left;
  margin-bottom: 6rem !important;
}

.button-add{
  color: #fff;
  background-color: #7e7e7e;
  margin-top: 35px;
  text-transform: uppercase;
  white-space: normal;
  border: 0;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  width: 22rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-add:focus {
  outline: 0;
  background-color: #575555;
}

.button-add:active {
  background-color: #575555;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.boxContainer_subpartner{
  display: inline-flex;
  margin-right: 5px;
  margin-bottom: 5px;
}

.box_subpartner{
  width: 100%;
  height: 30px;
  border-radius: 10px;
  padding: 0px 10px;
  color: #fff;
  background-color: #7e7e7e;
}

.button_remove {
  padding: 0 5px !important;
  color: white;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.error_duplicate{
  border: 1px solid red;
  border-radius: 10px;
  color: red;
  background-color: white;
}

.error_duplicate_button_remove{
  padding: 0 5px !important;
  color: red;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}
/* FERA-2791 [END] */
/* FERA-2792 [start] */
.RichEditor-editor {
  font-size: 0.8rem;
}
/* FERA-2792 [end] */


/* FERA-5570 [START] */
/* SELECT CATEGORIES */
.categories__popover {
  width: 100%;
}
.categories__popover .popover__content{
  left: auto;
  right: -50px ! important;
  height: 35px;
  width: 135px;
}

.categories__button {
  border: none;
  border-radius: 50%;
  font-size: 18px;
  width: 35px;
  height: 35px;
  font-weight: bold;
}
.categories__button:focus {
  outline:none;
}

.categories__details{
  display: flex;
    width: 100%;
    justify-content: end;
}

.line{
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.18);
  margin: 15px 0;
}
.select__control {
  position: relative;
  box-sizing: border-box;
}
.select__container {
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  margin-bottom: 10px;
}
.select__search {
  -webkit-box-align: center;
  align-items: center;
  display: grid;
  flex: 1 1 0%;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  padding: 2px 8px;
  box-sizing: border-box;
}
.select__search input {
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: 1;
  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
}
.select__separator {
  align-self: stretch;
  width: 1px;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}
.select__indicator {
  display: flex;
  transition: color 150ms ease 0s;
  color: rgb(204, 204, 204);
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
}
.select__options {
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  margin-top: 10px;
  padding: 10px;
  overflow: auto;
  height: 150px;
}
.select__options ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.select__options ul li {
  list-style: none;
}
.select__options ul li input {
  margin-right: 5px;
}
.select__options ul li button {
  cursor: pointer;
  color: #fff;
  background-color: #7e7e7e;
  text-transform: uppercase;
  border: 0;
  border-radius: 0.125rem;
}

.select__footer {
  display: flex;
  justify-content: end;
}
.select__footer--button-ok {
  margin-right: 10px
}
.select__result .clean {
  font-size: 12px;
  padding: 2px 12px;
  /* background: rgba(0, 0, 0, .3); */
  margin-right: 2px;
  border-radius: 3px;
  /* border: solid 1px rgba(0, 0, 0, .3); */
  color: #2d2e30;
}
.select__result .details {
  font-size: 12px;
  padding: 2px 12px;
  background: rgba(0, 0, 0, .3);
  margin-right: 2px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, .3);
  color: #2d2e30;
}

/* FERA-5570 [END] */

/* FERA-5570 [START] */
.popover__details {
  font-size: 16px !important;
  font-weight: 700;
}
/* FERA-5570 [END] */
